global.attachmentViewer = {
  loadingStatus: {},

  toggleAttachment: function (link) {
    if (link.getAttribute('data-photo') === 'true') {
      var path = link.getAttribute('file_path');

      window.open( this.fileURL(path), '_blank');
      return;
    }

    if (link.getAttribute('data-sidenav') === 'true') {
      var sidenavContent = $('#sidenav_content');
      attachmentViewer.showInSidenav(link, sidenavContent);
    } else {
      attachmentViewer.toggleInTable(link);
    }
  },

  showInSidenav: function (link, sidenavContent) {
    sidenav.open();
    attachmentViewer.loadingStatus = {};
    mdFormHelper.showSpinner();
    attachmentViewer.showAttachmentFromLink(link, sidenavContent);
  },

  toggleInTable: function(link){
    var container = $('#attachment_view' + link.id);

    container.toggle();
    $("#attachment_" + link.id + " .button").toggle();

    if (container.is(":visible")) {
      attachmentViewer.showAttachmentFromLink(link, container);
    }
  },

  showAttachmentFromLink: function(link, container) {
    var filePath = $(link).attr('file_path');
    this.showAttachment(filePath, container);
  },

  showAttachment: function(filePath, container) {
    var requests = 0,
        interval;

    if (typeof container === 'string') {
      container = $(container);
    }

    if (!filePath) return;

    var iframe = attachmentViewer.createIframe(filePath);

    iframe.onload = function() {
      mdFormHelper.hideSpinner();
      clearInterval(interval);
    }

    setTimeout(function() {
      container.html(iframe);

      interval = setInterval(function() {

        if (requests < 5) {
          requests++;
          var timestamp = new Date().getTime();
          urlHandler.updateIframeSrc(iframe, 'reload`', timestamp);
        } else {
          iframe.onload();
        }
      }, 3000);
    }, 500);
  },

  fileURL: function (path) {
    return window.location.origin + path;
  },

  createIframe: function (path){
    var gViewURL = "https://docs.google.com/gview?url=" + this.fileURL(path) + "&embedded=true",
        iframe = document.createElement("iframe");

    setTimeout(mdFormHelper.showSpinner, 300);

    iframe.onload = function() {
      mdFormHelper.hideSpinner;
    }

    iframe.setAttribute("src", gViewURL);
    iframe.setAttribute("frameborder", "0");
    iframe.width = "100%";
    iframe.height = "480px";
    return iframe;
  }
};
